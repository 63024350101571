<template>
  <div class="position-relative">

    <b-row>
      <b-col>
        <div v-if="selectedItems.length === 0">
          <p class="mar-top">Please select an order to change order details or other actions.</p>
        </div>
        <div v-else>
          <b-dropdown variant="primary" text="Actions" class="mr-1">
            <b-dropdown-item>
              <b-button class="btn-mar" v-on:click="generatePdf" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary">
                PDF Generate Label

              </b-button>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button class="btn-mar" v-on:click="markLabelAsGenerated" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary">
                Mark As Label Generated
              </b-button>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <b-button class="ml-1" v-on:click="refresh" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          Refresh
        </b-button>
      </b-col>

      <b-col md="4" class="my-1">
        <b-form-group label-cols-sm="3" label-align-sm="right" label-size="md" label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input v-model="orderFilter" @input="get" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!orderFilter" @click="clear()">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>


      <b-col md="2" sm="4" class="my-1">
        <b-form-group label-cols-sm="4" label-align-sm="right" label-size="sm" label-for="perPageInput">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
            @change="savePerPage(perPage)" />
        </b-form-group>
      </b-col>


      <b-col cols="12">
        <span>Total Rows : <b>{{ totalRows }}</b></span>
        <b-table :sticky-header="false" :items="calculatedOrders()" :fields="fields" striped responsive small
          id="my-table" :tbody-tr-class="rowClass" ref="tableToScroll">

          <template #cell(select)="row">
            <b-form-checkbox v-model="row.item.checkboxStatus" v-on:change="selected(row.item.orderID)"
              class="custom-control-primary">
              <feather-icon v-b-tooltip.hover title="Label has been generated" v-if="row.item.generated == 1"
                icon="TagIcon" />
            </b-form-checkbox>
            <small>{{ row.item.label_creation_dt }}</small>
          </template>

          <template #cell(orderID)="row">
            <span>{{ row.item.salesRecord }}</span>
            <br><br>
            <span>{{ row.item.orderID }}</span>
            <br><br>
            <small>{{ row.item.creation }}</small>
            <br>
            <span class="cursor-pointer" @click="row.toggleDetails">
              <feather-icon v-if="!row.detailsShowing" v-model="row.detailsShowing" icon="PlusIcon" />
              <feather-icon v-else icon="MinusIcon" v-model="row.detailsShowing" />
              <span>{{ row.detailsShowing ? 'Hide' : 'Show Details' }}</span>
            </span>

          </template>

          <template #cell(image)="row">

            <span class="mt-1" style="list-style-type: none;" v-for="product in row.item.products" :key="product.id">
              <div v-lazy-container="{
                selector: 'img',
                error:
                  'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                loading:
                  'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
              }">
                <img :src="product.productImageUrl" width="100px">
                <br>
              </div>
            </span>

          </template>

          <template #cell(sku)="row">
            <div style="width:170px;" v-for="product in row.item.products" :key="product.id">
              <span>{{ product.sku }}</span>
              <span class="mr-1"><b> x</b> {{ product.quantity }}</span>
              <feather-icon icon="ExternalLinkIcon" @click="openEbay(product.product_id)" />
              <br><br>
            </div>

          </template>

          <template #cell(price)="row">
            <div>
              <span v-for="product in row.item.products" :key="product.id">
                AU ${{ product.price }}
                <br><br>
              </span>
            </div>

          </template>

          <template #cell(carrier)="row">
            <div>
              {{ row.item.carrier }}
            </div>
          </template>

          <template #cell(title)="row">
            <div>
              <span v-for="product in row.item.products" :key="product.id">
                <span>{{ product.product_name }}</span>

                <br><br>
              </span>
            </div>
          </template>

          <template #cell(ShipTo)="row">
            <span>{{ row.item.buyerName }}<br>{{ row.item.ShipTo }}<br>{{ row.item.postcode }}</span>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">

                <b-col md="4" class="mb-1">
                  <strong>Status : </strong>{{ row.item.orderStatus }}
                </b-col>


                <b-col md="4" class="mb-1">
                  <strong>Username </strong>{{ row.item.buyerEbayUserName }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Phone : </strong>{{ row.item.phone }}
                </b-col>

                <b-col md="4" class="mb-1">
                  <strong>Carrier : </strong>{{ row.item.carrier }}
                </b-col>


                <b-col md="4" class="mb-1">
                  <strong>ShipTo : </strong>{{ row.item.ShipTo }}
                </b-col>

                <b-col md="4" class="mb-1">
                  <strong>Post Code : </strong>{{ row.item.postcode }}
                </b-col>

                <b-col md="4" class="mb-1">
                  <strong>Buyer Name : </strong>{{ row.item.buyerName }}
                </b-col>


                <b-col md="4" class="mb-1">
                  <strong>Description : </strong><span v-html="row.item.Description"></span>
                </b-col>

              </b-row>



              <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
                Hide Details
              </b-button>
            </b-card>
          </template>
        </b-table>

      </b-col>

      <b-col cols="12">
        <!-- <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" first-number last-number
          class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="onPageChangeByUser"> -->

        <LaravelVuePagination class="m-t-10" :data="orders" :limit=2 @pagination-change-page="get"></LaravelVuePagination>

        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
        </b-pagination>
      </b-col>

    </b-row>
    <b-overlay :show="show" no-wrap spinner-variant="primary" />
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge, BPagination, VBTooltip,
  BFormGroup,
  BDropdown,
  BFormSelect,
  BInputGroup,
  BDropdownItem,
  BFormInput,
  BInputGroupAppend,
  BOverlay
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import { PDFDocument, rgb } from 'pdf-lib'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LaravelVuePagination from 'laravel-vue-pagination';

export default {
  components: {
    LaravelVuePagination,
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BDropdown,
    BCol,
    BBadge,
    BAvatar,
    BPagination,
    ToastificationContent,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BDropdownItem,
    BInputGroupAppend,
    BOverlay
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      pageOptions: [25, 50, 100, 200],
      perPage: '',
      currentPage: 1,
      fields: ['select', 'orderID', 'image', 'sku', 'price', 'title', 'carrier', 'ShipTo'],
      orders: {
        data: []
      },
      selectedItems: [],
      totalRows: 1,
      filter: null,
      filterOn: [],
      show: false,
      pageChangeCausedByUserInteraction: false,
      orderFilter: '',
    }
  },
  mounted() {
    this.perPage = 25
    this.get();
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage !== oldPage) {

        if (newPage !== oldPage && !this.pageChangeCausedByUserInteraction) {
          this.scrollToTop();
        }
        this.pageChangeCausedByUserInteraction = false;
      }
    },
    currentPage(newPage, oldPage) {
      // Scroll to the table element when the page changes
      if (newPage !== oldPage) {
        this.delayedScrollToTable();
      }
    },
  },
  methods: {
    savePerPage(e) {
      //  console.log('e',e);
      this.perPage = e
      this.get();
    },
    scrollToTop() {
      const mainContainer = this.$refs.mainContainer;
      if (mainContainer) {
        mainContainer.scrollTop = 0;
      }
    },
    delayedScrollToTable() {
      setTimeout(() => {
        const tableToScroll = this.$refs.tableToScroll;
        if (tableToScroll) {
          tableToScroll.$el.scrollIntoView();
        }
      }, 100); // Adjust the delay as needed
    },
    onPageChangeByUser() {
      this.pageChangeCausedByUserInteraction = true;
    },
    async get(page = 1, query) {
      try {
        this.show = true
        query = this.orderFilter.length > 0 ? this.orderFilter : undefined;
        await axios.get(process.env.VUE_APP_API + `/vieworders?page=${page}&query=${query}&per_page=${this.perPage}`)
          .then(response => {

            this.orders = response['data']
            this.show = false
            this.totalRows = this.orders.total
          })
      }
      catch (error) {
        console.log('error', error);
        this.show = false
        return []
      }
    },
    async refresh() {
      this.show = true
      await axios.get(process.env.VUE_APP_API + '/orders')
        .then(res => {
          if (res.data == 'success in api') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'AlertOctagonIcon',
                text: 'Order refresh successfully',
                variant: 'success',
              },
            },
              {
                position: 'top-right'
              })
            this.get()
            this.show = false
            // axios.get(process.env.VUE_APP_API + '/vieworders')
            //   .then(res => {
            //     this.orders = res['data']
            //     this.show = false
            //   })
          }
          else {
            this.show = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertOctagonIcon',
                text: 'Can not fetch orders from Ebay',
                variant: 'danger',
              },
            },
              {
                position: 'top-right'
              })
          }
        })
    },
    selected(id) {
      var status = false

      for (var i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i] == id) {
          status = true
        }
      }
      if (status) {
        const index = this.selectedItems.indexOf(id);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
      else {
        this.selectedItems.push(id)
      }
    },
    generatePdf() {
      if (this.selectedItems.length != 0) {
        axios({
          method: 'post',
          url: process.env.VUE_APP_API + '/shipping/pdf',
          data: this.selectedItems
        })
          .then(res => {
            window.open(process.env.VUE_APP_PDF_LABEL_CDN + `/${res.data}`, '_blank')
            axios.get(process.env.VUE_APP_API + '/vieworders')
              .then(res => this.orders = res['data'])
          })

        for (let index = 0; index < this.orders.length; index++) {
          const element = this.orders[index];
          element.checkboxStatus = false
        }
        this.selectedItems = []
      }
      else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No row(s) selected',
            icon: 'AlertOctagonIcon',
            text: 'Select row(s) to generate pdf labels',
            variant: 'danger',
          },
        },
          {
            position: 'top-center'
          })
      }
    },
    openEbay(id) {
      window.open(`https://www.ebay.com.au/itm/${id}`, '_blank')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // the row color
    rowClass(item, type) {
      const isLabelGenerated = 'table-success'
      const isOrderInternational = 'table-warning'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.generated == true) { return isLabelGenerated }

      if (item.carrier.toLowerCase().includes("international")) { return isOrderInternational }
    },
    async markLabelAsGenerated() {
      try {
        if (this.selectedItems.length != 0) {
          this.show = true
          await axios({
            method: 'post',
            url: process.env.VUE_APP_API + '/mark-label-generated',
            data: this.selectedItems
          })
            .then(res => {
              if (res.data.success) {

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'AlertOctagonIcon',
                    text: 'Marked as Label Generated successfully',
                    variant: 'success',
                  },
                },
                  {
                    position: 'top-right'
                  })
                this.get()
                this.show = false
              }
              else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Failure',
                    icon: 'AlertOctagonIcon',
                    text: 'Marked as Label Generated Failed',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-right'
                  })
              }
            }
            )

          for (let index = 0; index < this.orders.length; index++) {
            const element = this.orders[index];
            element.checkboxStatus = false
          }
          this.selectedItems = []
        }
        else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'No row(s) selected',
              icon: 'AlertOctagonIcon',
              text: 'Select row(s) to mark label generated',
              variant: 'danger',
            },
          },
            {
              position: 'top-center'
            })
        }
      }
      catch (error) {
        this.show = false
        return []
      }
    },
    clear() {
      this.orderFilter = ''
    },
    calculatedOrders() {
      if (this.filter) {
        return this.orders
      }
      else {
        return this.orders.data.filter(order => (order.generated == false))
      }
    },
    isLabelBlackListed(carrier, products) {

      let title = products[0]['product_name'].toLowerCase();
      if (carrier.toLowerCase().includes('international')) {
        if (title.includes('grille') || title.includes('headlights') || title.includes('bumper bar')) {
          return true
        }
      }
      else {
        return false
      }
    },
  }
}
</script>

<style scoped >
#ul {
  text-align: left;
  list-style-type: none;
}

.mar-top {
  margin-top: 3%;
}

.btn-primary {
  margin-top: 1%;
  /* margin-left: 12% !important; */
  border-color: #921010 !important;
  background-color: #921010 !important;
}

.printed {
  background: red;
  padding-left: 24px;
  margin-right: 23px;
}
</style>